@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper {
    max-width: 1000px;
    max-height: 400px;
}

.map{
    width: 1000px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Оптимизация под мобильные устройства */
@media (max-width: 767px) {
    .swiper {
        width: 400px;
        height: 300px; /* Уменьшаем высоту для мобильных */
    }

    .swiper-slide img {
        max-width: 400px;
        max-height: 300px; /* Уменьшаем высоту для мобильных */
        object-fit: cover;
    }

    .map{
        width: 100%;
    }
}

/* Оптимизация под устройства среднего размера */
@media (min-width: 768px) and (max-width: 1023px) {
    .swiper {
        max-width: 90%;
        max-height: 400px; /* Сохраняем высоту для планшетов */
    }

    .swiper-slide img {
        max-width: 100%;
        max-height: 400px; /* Сохраняем высоту для планшетов */
        object-fit: cover;
    }

    .map{
        width: 100%;
    }
}

/* Оптимизация под устройства с широким экраном */
@media (min-width: 1024px) {
    .swiper {
        max-width: 1000px; /* Возвращаем максимальную ширину для широких экранов */
        max-height: 400px;
    }

    .swiper-slide img {
        max-width: 100%;
        max-height: 400px;
        object-fit: cover;
    }

    /*.map{*/
    /*    width: 1000px;*/
    /*}*/
}

/* Оптимизация под маленькие мобильные устройства */
@media (max-width: 479px) {
    .swiper {
        max-width: 100%;
        max-height: 250px; /* Уменьшаем высоту для очень маленьких экранов */
    }

    .swiper-slide img {
        width: 100%;
        height: 250px; /* Уменьшаем высоту для очень маленьких экранов */
        object-fit: cover;
    }

    .map{
        width: 100%;
    }

    p, h1, h2, h3, h4, h5, h6 {
        font-size: 5px; /* 1em / 1.5 = 0.67em */
    }
}

.ant-picker-time-panel-column {
    width: 300px; /* Установите нужную ширину */
}


.register-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 85vh;
    width: 400px; /* Ширина формы */
}

.login-tip {
    margin-top: 16px;
    text-align: center;
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 85vh;
    width: 400px; /* Ширина формы */
}

.register-tip {
    margin-top: 16px;
    text-align: center;
}

@media (max-width: 767px)
.mySwiper {
    width: 400px;
    height: 100px;
}